import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PageResponse } from '@core/shared/domain';

import { ApiKey } from '../models';

@Injectable()
export class ApiKeysService {
  private readonly baseApiPath = 'api/m/kernel/users/apikey';

  constructor(private readonly httpClient: HttpClient) {}

  getAll(params?: HttpParams): Observable<PageResponse<ApiKey>> {
    return this.httpClient.get<PageResponse<ApiKey>>(`${this.baseApiPath}`, { params });
  }

  update(apiKey: ApiKey): Observable<ApiKey> {
    return this.httpClient.put<ApiKey>(`${this.baseApiPath}/${apiKey.apiKey}`, apiKey);
  }

  createApiKey(apiKey: ApiKey): Observable<ApiKey> {
    return this.httpClient.post<ApiKey>(`${this.baseApiPath}`, apiKey);
  }

  deleteApiKey(apiKey: ApiKey): Observable<ApiKey> {
    return this.httpClient.delete<ApiKey>(`${this.baseApiPath}/${apiKey.apiKey}`);
  }
}
