import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NotificationService } from '@core/shared/util';

import { ApiKey, ApiKeyItem } from '../models';

import { ApiKeysService } from './api-keys.service';

@Injectable()
export class ApiKeysFacade {
  private readonly _apiKeys$ = new BehaviorSubject([] as ApiKeyItem[]);
  readonly apiKeys$: Observable<ApiKeyItem[]> = this._apiKeys$.asObservable();

  constructor(
    private readonly service: ApiKeysService,
    private readonly notificationService: NotificationService,
  ) {}

  updateApiKey(apiKey: ApiKey): void {
    this.service.update(apiKey).subscribe({
      next: () => {
        this.notificationService.toastSuccess('Die Änderungen wurden gespeichert.');
        this.getAll();
      },
    });
  }

  createApiKey(): void {
    this._apiKeys$.next([...this._apiKeys$.value, { editMode: true, key: {} as ApiKey }]);
  }

  saveCreateApiKey(apiKey: ApiKey): void {
    this.service.createApiKey(apiKey).subscribe({
      next: () => {
        this.notificationService.toastSuccess('Der API-Key wurde erfolgreich gespeichert.');
        this.getAll();
      },
    });
  }

  deleteApiKey(apiKey: ApiKey): void {
    if (!apiKey.apiKey) {
      const values = this._apiKeys$.getValue();
      const index = values.findIndex((k) => k.key === apiKey);
      if (index > -1) {
        this._apiKeys$.next([...values.slice(0, index), ...values.slice(index + 1)]);
      }
      return;
    }

    this.service.deleteApiKey(apiKey).subscribe({
      next: () => {
        this.notificationService.toastSuccess('Der API-Key wurde erfolgreich gelöscht.');
        this.getAll();
      },
    });
  }

  getAll(): void {
    this.service
      .getAll()
      .pipe(
        map((key) => {
          const apiKeys: Array<ApiKeyItem> = key.data.map((item) => {
            return {
              editMode: false,
              key: item,
            };
          });
          return apiKeys;
        }),
      )
      .subscribe({
        next: (result) => {
          this._apiKeys$.next(result);
        },
      });
  }
}
